// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
//
import 'virtual:windi-base.css';
import 'virtual:windi-components.css';
import 'virtual:windi-utilities.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'sweetalert2/src/sweetalert2.scss';
import "toastify-js/src/toastify.css";
import '~/stylesheets/plugins/flatpickr.scss';
import '@simonwep/pickr/dist/themes/nano.min.css';
import '~/stylesheets/application.scss';

import '~/plugins/money.js';
import '@hotwired/turbo-rails';

import '~/plugins/action_text.js';

import '@oddcamp/cocoon-vanilla-js';

import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import Chart from '@stimulus-components/chartjs'

const application = Application.start();
const controllers = import.meta.globEager('../**/*_controller.js');
registerControllers(application, controllers);
const app = Application.start();
application.register('chart', Chart)

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
